
import { Component, Vue } from 'vue-property-decorator';
import Avatar from '@/components/user/Avatar.vue';
import DeleteMemberDialog from '@/components/workspaceSetting/DeleteMemberDialog.vue';
import { Validations } from 'vuelidate-property-decorators';
import { EMAIL_REGEX } from '@/scripts/shared';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/types';
import { Member } from '@/store/workspace/models';

const workspaceNamespace: string = 'workspace';
const userNamespace: string = 'user';

@Component({
  components: {
    Avatar,
    DeleteMemberDialog,
  },
})
export default class WorkspaceMembers extends Vue {
  @Getter(types.GET_WORKSPACE, { namespace: workspaceNamespace }) getWorkspace: any;

  @Action(types.ADD_NEW_MEMBER, { namespace: workspaceNamespace }) addNewMember: any;

  @Getter(types.GET_LOADING, { namespace: workspaceNamespace }) getLoading: any;

  @Getter(types.GET_USER, { namespace: userNamespace }) getUser: any;

  private email: string = '';

  private deleteDialog = false;

  private headers = [
    {
      text: 'Avatar',
      align: 'start',
      value: 'avatar',
    },
    {
      text: this.$t('email'),
      align: 'start',
      value: 'email',
    },
    {
      text: this.$t('role'),
      align: 'end',
      value: 'role',
    },
    {
      text: this.$t('actions'),
      align: 'end',
      value: 'actions',
    },
  ];

  private members: Member[] = [];

  private search: string = '';

  get emailErrors() {
    const errors: any[] = [];
    if (!this.$v.email.$dirty) return errors;
    if (!this.$v.email.matches) errors.push(this.$t('email_invalid'));
    return errors;
  }

  get getAllMembers() {
    const { owner } = this.getWorkspace;
    let { members } = this.getWorkspace;
    owner.isOwner = true;
    if (members.length === 0 || members[0] === '') {
      return [owner];
    }

    // if the user is the owner, can remove all the members except her/himself
    // if user is a member of workspace only can remove her/himself
    // Here adding the "canRemoveMember" into members
    if (this.getUser) {
      if (this.getUser.id === owner.id) {
        // User is the owner
        members = members.map((m: any) => {
          const member = m;
          member.canRemoveMember = true;
          return member;
        });
      } else {
        // User is not the owner
        members = members.map((m: any) => {
          const member = m;
          if (m.id === this.getUser.id) {
            member.canRemoveMember = true;
          }
          return member;
        });
      }
    }
    return [...members, owner];
  }

  @Validations()
  validations = {
    email: {
      matches(value: string) {
        if (value) {
          return EMAIL_REGEX.test(value.trim());
        }
        return true;
      },
    },
  };

  isEmailAlreadyAdded() {
    const { email } = this;
    const isIncluded = this.getAllMembers.findIndex(
      (m: Member) => m.email.toLowerCase() === email.trim().toLowerCase(),
    );
    return isIncluded === -1;
  }

  async addMember() {
    this.$v.$touch();
    if (this.$v.$invalid || !this.email) return;
    if (!this.isEmailAlreadyAdded()) {
      this.email = '';
      return;
    }

    const req = {
      workspaceId: this.getWorkspace.id,
      email: this.email.toLowerCase().trim(),
    };
    await this.addNewMember(req);

    if (!this.getLoading) {
      this.email = '';
    }
  }

  get isOwnerRemove() {
    return this.getUser.id === this.getWorkspace.owner.id;
  }

  isYou(id: string): boolean {
    if (this.getUser) {
      return this.getUser.id === id;
    }
    return false;
  }
}
